import { S3Client } from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'

class S3Uploader {

  constructor() {
    this.form = document.querySelector('._s3_uploader');
    if (!this.form) return;
    this.form.onsubmit = () => this.validate();
    this.mfile = document.querySelector('._up_mfile'); this.mfile_flag = document.querySelector('._up_mfile_flag');
  }

  uploadMedia() {
    let bucket = document.querySelector('._s3_bucket').value, key = document.querySelector('._s3_key').value;
    let upload = new Upload({
      client: new S3Client({
        region: 'us-1',
        credentials: {
          accessKeyId: this.form.dataset.s3User,
          secretAccessKey: this.form.dataset.s3Pass
        },
        endpoint: this.form.dataset.s3Endp
      }),
      params: { Bucket: bucket, Key: key, ContentType: '', Body: this.getFile(this.mfile) },
      partSize: 5 * 1024 * 1024,
      leavePartsOnError: false
    });
    upload.on('httpUploadProgress', (progress) => {
      let percentage = `${(progress.loaded * 100 / progress.total).toFixed()}%`;
      let inner = document.querySelector('._progress .progress-inner');
      inner.style.width = inner.innerHTML = percentage;
    });
    document.querySelector('._progress').style.display = 'block';
    upload.done()
    .then(() => {
      // we don't want Rails to read the file, so remember the upload, but clear the file
      if (this.hasFile(this.mfile)) this.mfile_flag.value = 'uploaded';
      this.mfile.value = '';
      this.form.onsubmit = null;
      this.form.submit();
    })
    .catch(error => {
      document.querySelector('._error-description').innerHTML = error;
      document.querySelector('._progress').style.display = 'none'; document.querySelector('._error').style.display = 'block';
    });
  }

  hasFile(fileField) {
    return fileField && fileField.files.length > 0;
  }

  getFile(fileField) {
    return fileField.files[0];
  }

  uploadMediaAsync() {
    if (!this.hasFile(this.mfile)) {
      this.form.onsubmit = null;
      this.form.submit();
    } else {
      this.uploadMedia();
    };
  }

  validate() {
    let date = document.querySelector('._audio_key').value;
    if (!date.match(/^\d{4}-\d{2}-\d{2}$/) || isNaN(new Date(`${date}T00:00`))) {
      alert(`"${date}" is not a valid date in YYYY-MM-DD format.`);
      return false;
    }
    fetch(`/radio_programs/check-date/${document.querySelector('#episode_id').value || 0}/${document.querySelector('#episode_code').value}`)
    .then(response => response.json())
    .then((data) => {
      if ('OK' == data)
        this.uploadMediaAsync();
      else
        alert('An episode with this date already exists.  Please check the date and try again.');
    })
    return false;
  }
}

export default S3Uploader;

