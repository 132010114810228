import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['key', 'date', 'code', 'name'];

  pad0(n) {
    return n > 9 ? n : `0${n}`;
  }

  // audio pseudo-SIMS code and S3 key depend on production date value
  updateKey() {
    let date = this.dateTarget.value;
    let key = date.match(/^\d{4}-\d{2}-\d{2}$/) ? date.replace(/-/g, '') : '00000000';
    if (this.timestamp) {
      this.keyTarget.value = this.keyTarget.value.replace(/\d{8}(.{6})\.mp3$/, `${key}$1.mp3`);
      this.codeTarget.value = this.codeTarget.value.replace(/\d{8}(.{6})$/, `${key}$1`);
    } else {
      this.keyTarget.value = this.keyTarget.value.replace(/\d{8}\.mp3$/, `${key}.mp3`);
      this.codeTarget.value = this.codeTarget.value.replace(/\d{8}$/, key);
    }
  }

  // auto-populate audio name from file name, if name not already entered
  // also populate production date from file if program allows multiple episodes in a single day (code ends in timestamp)
  updateName(e) {
    if (this.nameTarget.value != '') return;
    let file = e.target.files[0];
    this.nameTarget.value = file.name.normalize().replace(/\.mp3$/, '');
    if (this.timestamp) {
      let date = new Date(file.lastModified),
          fdate = `${date.getFullYear()}-${this.pad0(date.getMonth() + 1)}-${this.pad0(date.getDate())}`;
      this.dateTarget.value = fdate;
      this.dateTarget.dispatchEvent(new Event('change'), { bubbles: true });
    }
  }

  connect() {
    this.timestamp = this.element.dataset.ts == 'true';
  }
}