import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  labels = {
    en: {
      monthNames: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
      dayNames: [ 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa' ],
      error: 'Please enter a valid date in YYYY-MM-DD format'
    },
    es: {
      monthNames: [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ],
      dayNames: [ 'Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá' ],
      error: 'Por favor escriba una fecha válida en formato AAAA-MM-DD'
    }
  }

  fill2(n) {
    return n < 10 ? `0${n}` : n;
  }

  formatDate(year, month, day) {
    if (typeof year != 'number') { day = year.getDate(); month = year.getMonth() + 1; year = year.getFullYear(); }
    return `${year}-${this.fill2(month)}-${this.fill2(day)}`;
  }

  genRange(cw, first, last, year, month, highlight, style) {
    let s = ''
    for (let i = first; i <= last; i++) {
      if (cw == 0) s += '<tr>';
      let date = this.formatDate(year, month, i);
      s += `<td data-date="${date}" class="${date == highlight ? 'ui-datepicker-current-date ' : ''}${style}">${i}</td>`;
      if (cw == 6) s += '</tr>';
      cw = (cw + 1) % 7;
    }
    return s;
  }

  genMonth(value, highlight) {
    let date = !value ? new Date() : new Date(`${value}T00:00`);
    if (isNaN(date)) return `<div class="ui-datepicker-invalid-date">${this.labels[this.lang].error}</div>`;
    let [year, month] = [date.getFullYear(), date.getMonth()];
    
    let cFirst = new Date(year, month, 1);
    let pLast = new Date(cFirst - 1);
    let [pYear, pMonth, pDay] = [pLast.getFullYear(), pLast.getMonth(), pLast.getDate()];
    let wDay = cFirst.getDay();
    
    let nYear = year + (month > 10 ? 1 : 0);
    let nMonth = month > 10 ? 0 : month + 1;
    let nFirst = new Date(nYear, nMonth, 1);
    let cLast = new Date(nFirst - 1);

    let s = `<table><tr class="ui-datepicker-header"><td class="ui-datepicker-control" data-next="${pYear}-${this.fill2(pMonth + 1)}-01">&lt;</td>
      <td colspan="5">${this.labels[this.lang].monthNames[month]} ${year}</td><td class="ui-datepicker-control" data-next="${nYear}-${this.fill2(nMonth + 1)}-01">&gt;</td>
      </tr><tr class="ui-datepicker-day-names"><td>${this.labels[this.lang].dayNames.join('</td><td>')}</td></tr>`;
    if (wDay > 0) s += this.genRange(0, pDay - wDay + 1, pDay, pYear, pMonth + 1, null, 'ui-datepicker-other');
    s += this.genRange(wDay, 1, cLast.getDate(), year, month + 1, highlight, 'ui-datepicker-current');
    if (nFirst.getDay() > 0) s += this.genRange(nFirst.getDay(), 1, 7 - nFirst.getDay(), nYear, nMonth + 1, null, 'ui-datepicker-other');

    return `${s}</table>`;
  }

  positionWidget() {
    let rect = this.element.getBoundingClientRect();
    this.widget.style.left = `${rect.left}px`;
    this.widget.style.top = rect.top < window.innerHeight / 2 ?
      `${rect.bottom + document.documentElement.scrollTop + 2}px` :
      `${rect.top + document.documentElement.scrollTop - this.widget.scrollHeight -2}px`;
  }

  installHandlers() {
    window.onkeydown = (e) => { if (e.key == 'Escape' || e.key == 'Tab') this.hide(); } 
    window.onclick = (e) => { if (e.target != this.element) this.hide() }; 
    this.widget.onclick = (e) => {
      e.stopPropagation();
      if (e.target.dataset.date) {
        this.hide();
        if (this.element.dataset.targetUrl)
          window.location = `${this.element.dataset.targetUrl}/${e.target.dataset.date}`;
        else {
          this.element.value = e.target.dataset.date;
          this.element.dispatchEvent(new Event('change', { bubbles: true }));
        }
      } else if (e.target.classList.contains('ui-datepicker-control')) {
        this.widget.innerHTML = this.genMonth(e.target.dataset.next, this.element.value);
        this.positionWidget();
      }
    }
  }

  uninstallHandlers() {
    window.onkeydown = window.onclick = this.widget.onclick = null;
  }

  show() {
    this.widget.innerHTML = this.genMonth(this.element.value, this.element.value ? this.element.value : this.formatDate(new Date()));
    this.positionWidget();
    this.installHandlers();
  }

  hide() {
    this.widget.style.top = '-1000px';
    this.uninstallHandlers();
  }

  connect() {
    this.lang = document.getElementsByTagName('html')[0].lang;
    this.widget = document.getElementById('ui-datepicker-widget');
    if (!this.widget) {
      this.widget = document.createElement('div');
      this.widget.id = 'ui-datepicker-widget';
      this.widget.classList.add('ui-datepicker');
      this.widget.style.position = 'absolute';
      this.widget.style.top = '-1000px'; // render off-screen to get initial height
      document.body.appendChild(this.widget);
    }
    this.element.onfocus = this.element.onclick = (e) => {
      e.stopPropagation();
      this.show();
    };
    this.element.onkeyup = (e) => {
      e.stopPropagation();
      this.widget.innerHTML = this.genMonth(this.element.value, this.element.value ? this.element.value : this.formatDate(new Date()));
      this.positionWidget();
    }
  }
}