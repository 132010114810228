// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import S3Uploader from './src/s3-uploader'
import TusUploader from "./src/tus-uploader"

let rowHandler = (e) => {
  if (e.target.tagName.toLowerCase() == 'a') return;
  e.stopPropagation();
  Turbo.visit(e.currentTarget.dataset.href);
};

document.addEventListener('turbo:load', () => {
  new S3Uploader();
  new TusUploader();
  
  let rows = document.querySelectorAll('[data-href]');
  for (let i = 0; i < rows.length; i++) {
    rows[i].onclick = rowHandler;
  }
});