class TusUploader {

  constructor() {
    this.form = document.querySelector('._tus_uploader');
    if (!this.form) return;
    this.form.onsubmit = () => this.uploadMedia();
    this.assets = [];
    this.tus = require('tus-js-client');
    this.code = document.querySelector('._code');
    this.mfile = document.querySelector('._up_mfile'); this.mfile_flag = document.querySelector('._up_mfile_flag');
    this.ifile = document.querySelector('._up_ifile'); this.ifile_flag = document.querySelector('._up_ifile_flag');
    this.cfile = document.querySelector('._up_cfile'); this.cfile_flag = document.querySelector('._up_cfile_flag');
  }

  uploadItems() {
    this.assets.forEach(asset => {
      asset.status = 'pending';
      let file = this.getFile(asset.file_field);
      asset.upload = new this.tus.Upload(file, {
        endpoint: 'https://omega.ewtnos.com/uploads',
        chunkSize: 64 * 1024 * 1024,
        metadata: {
          code: this.code.value,
          kind: asset.kind,
          token: this.form.dataset.omegaToken
        },
        onError: this.uploadError,
        onSuccess: this.uploadSuccess.bind(this, asset),
        onProgress: this.uploadProgress
      });
      asset.upload.findPreviousUploads().then(prevUploads => {
        if (prevUploads.length > 0) {
          let ans = prompt(`You previously started to upload ${file.name}.\nDo you want to continue where you left off?\n(Enter 'no' if you want to upload the file from the beginning.)`);
          if (ans.toLowerCase() != 'no')
            asset.upload.resumeFromPreviousUpload(prevUploads[0]);
        }
        asset.upload.start();
      })
    });
  }

  uploadSuccess(asset) {
    asset.file_field.value = ''; // tus already uploaded the file to omega; don't let mu read it again
    asset.flag_field.value = asset.upload.url.substr(asset.upload.url.lastIndexOf('/') + 1);
    asset.status = 'complete';
    if (!this.assets.find(a => 'pending' == a.status)) {
      this.form.onsubmit = null;
      this.form.submit();
    }
  }

  uploadError(error) {
    document.querySelector('._error-description').innerHTML = error;
    document.querySelector('._progress').style.display = 'none'; document.querySelector('._error').style.display = 'block';
  }

  uploadProgress(bytesUploaded, bytesTotal) {
    let percentage = `${(bytesUploaded * 100 / bytesTotal).toFixed()}%`;
    let inner = document.querySelector('._progress .progress-inner');
    inner.style.width = percentage; inner.innerHTML = percentage;
  }

  uploadMedia() {
    if (this.hasFile(this.mfile)) { this.assets.push({ kind: 'video', file_field: this.mfile, flag_field: this.mfile_flag }); }
    if (this.hasFile(this.ifile)) {
      if (this.getFile(this.ifile).size > 2097152) {
        alert(`The still image file you selected is larger than 2 MB and YouTube will not accept it. To reduce the file size, please make sure that the image is stored in JPEG (compressed) format and has the same resolution as the video.`);
        return false;
      }
      this.assets.push({ kind: 'poster', file_field: this.ifile, flag_field: this.ifile_flag });
    }
    if (this.hasFile(this.cfile)) {
      if (this.getFile(this.cfile).size > 2097152) {
        alert(`The closed captions file you selected is larger than 1 MB and YouTube will not accept it.  Please make sure the file is a valid VTT captions file.`);
        return false;
      }
      this.assets.push({ kind: 'captions', file_field: this.cfile, flag_field: this.cfile_flag });
    }
    if (this.assets.length < 1) return true;
    document.querySelector('._progress').style.display = 'block';
    this.uploadItems();
    return false;
  }

  hasFile(fileField) {
    return fileField && fileField.files.length > 0;
  }

  getFile(fileField) {
    return fileField.files[0];
  }
}

export default TusUploader;
